import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../config/http";

export const fetchUniversities = createAsyncThunk(
  "university/fetchUniversities",
  async () => {
    const res = await http.get("/universities");
    return res;
  }
);
