import { useNavigate } from "react-router-dom";
import { getUserData } from "../utils/helper.utils";

const Unauthorized = () => {
  const navigate = useNavigate();
  const { isAdmin } = getUserData();

  return (
    <>
      <div className="bg-warning h-100 d-flex justify-content-center align-items-center">
        <div className="text-white">
          <h1>
            <strong>401</strong> Unauthorized
          </h1>
          <p>Sorry, you are unauthorized to access this page</p>
          <button
            className="btn btn-dark w-25"
            onClick={() =>
              isAdmin ? navigate("/university") : navigate("/surveys")
            }
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
