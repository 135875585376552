// CONSTANT OBJECTS
export const STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
};

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  UNIVERSITY_ADMIN: "UNIVERSITY_ADMIN",
};

export const SUCCESS_TOAST = {
  LOGIN: "successLogin",
  SYNC: "successSync",
  LOGOUT: "successLogout",
  GET_USERS: "successGetUsers",
  GET_STUDENTS: "successGetStudents",
  GET_STUDENT_REPORT: "successGetStudentReport",
  GET_SURVEYS: "successGetSurveys",
  DOWNLOAD_PDF: "successPdf",
  SEARCH_RESULT: "successSearch",
};

export const ERROR_TOAST = {
  LOGIN: "errorLogin",
  SYNC: "errorSync",
  LOGOUT: "errorLogout",
  GET_USERS: "errorGetUsers",
  GET_SURVEYS: "errorGetSurveys",
  GET_STUDENTS: "errorGetStudents",
  GET_STUDENT_REPORT: "errorGetStudentReport",
  DOWNLOAD_PDF: "errorPdf",
  SEARCH_RESULT: "errorSearch",
};
export const PAGE_LIMIT = 15;
