import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSurveys } from "../../redux/survey/httpCalls.survey";
import { Pagination } from "../../components";
import ComponentHeader from "../../components/component-header";
import { getUserData } from "../../utils/helper.utils";
import { ROLES } from "../../utils/Constants";
import CardViewWithoutDownloadBtn from "../../components/cardview/CardViewWithoutDownloadBtn";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";

const SurveyList = () => {
  // const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [suveryData, setSurveyData] = useState([]);
  const dispatch = useDispatch();
  const { surveys, loading, metadata } = useSelector((state) => state.survey);
  const userData = getUserData();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let universityID;
    if (userData?.roleId?.role === ROLES.UNIVERSITY_ADMIN) {
      if (userData?.universitiesId?.universityName) {
        universityID = userData.universitiesId.universityName;
      } else {
        localStorage.clear();
        navigate("/login");
      }
    } else if (userData?.roleId?.role === ROLES.SUPER_ADMIN) {
      universityID = params?.universityId;
    } else {
      localStorage.clear();
      navigate("/login");
    }
    dispatch(fetchSurveys(universityID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSurveyData(surveys);
  }, [surveys]);

  return (
    <div className="mt-3">
      <ComponentHeader
        heading="Surveys"
        searchPlaceHolder="Search Survey"
        setCurrentPage={setCurrentPage}
        showSearchBox={false}
      />

      <div className="d-flex flex-wrap gap-3">
        {loading ? (
          <SkeletonLoader />
        ) : suveryData?.length ? (
          suveryData.map((survey, index) => {
            return (
              <CardViewWithoutDownloadBtn
                key={index}
                cardClickEvent={() => {
                  if (userData?.roleId?.role === ROLES.SUPER_ADMIN) {
                    navigate(
                      `/university/${params?.universityId}/${survey["_id"]}`
                    );
                  } else if (
                    userData?.roleId?.role === ROLES.UNIVERSITY_ADMIN
                  ) {
                    navigate(
                      `/surveys/${userData?.universitiesId?.universityName}/${survey["_id"]}`
                    );
                  } else {
                    localStorage.clear();
                    navigate("/login");
                  }
                }}
                cardContent={survey?.title}
              />
            );
          })
        ) : (
          <p className="text-light"> No Survey Found.</p>
        )}
      </div>

      <Pagination
        metadata={metadata}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};
export default SurveyList;
