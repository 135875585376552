import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../config/http";

let searchAboartController = false;

export const fetchStudents = createAsyncThunk(
  "student/fetchStudents",
  async (pathParams) => {
    const { universityId, surveyId, pagination } = pathParams;
    const res = await http.get(
      `/survey/students/list/${universityId}/${surveyId}`,
      {
        params: {
          ...pagination,
        },
      }
    );
    return res;
  }
);

export const fetchSearchStudents = createAsyncThunk(
  "student/fetchSearchStudents",
  async (pathParams) => {
    const { surveyId, universityName, limit, page, identificationKey } =
      pathParams;
    if (searchAboartController) {
      searchAboartController.abort();
    }
    searchAboartController = new AbortController();
    const res = await http.get(
      `/survey/university/student/search/${surveyId}/${universityName}`,
      {
        params: {
          limit,
          page,
          identificationKey,
        },
        signal: searchAboartController.signal,
      }
    );
    return res;
  }
);

// export const fetchStudentReport = createAsyncThunk(
//   "survey/fetchStudentReport",
//   async (params) => {
//     const res = await http.get(`/survey/userreport/${params}`);
//     return res;
//   }
// );
