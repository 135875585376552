import React, { useState } from "react";
import "./Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import site_logo from "../../assets/images/CA_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/user/httpCalls.user";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { downloadFile, getUserData } from "../../utils/helper.utils";
import { ROLES } from "../../utils/Constants";
import { syncData } from "../../redux/syncData/httpCalls.sync";
import { downloadExcel } from "../../redux/exportExcel/httpCalls.exportExcel";
import SyncBanner from "../syncBanner/SyncBanner";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const { loading: logoutLoading } = useSelector((state) => state.user);
  const { loading: excelLoading } = useSelector((state) => state.exportExcel);
  const { loading: syncLoading } = useSelector((state) => state.syncData);
  const [show, setShow] = useState(false);
  const userData = getUserData();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loggedOut = (e) => {
    dispatch(
      logout(function cb() {
        navigate("/login");
      })
    );
    e.currentTarget.disabled = true;
  };
  return (
    <>
      {syncLoading ? <SyncBanner /> : null}
      <nav className="navbar bg-white">
        <div className="container">
          <ul className="navbar-left-menu-container navbar-logo-left">
            <li style={{ marginRight: "1rem" }}>
              <NavLink to="/">
                <img
                  src={site_logo}
                  alt="Svadhi logo"
                  className="navbar-brand m-0 site-logo"
                />
              </NavLink>
            </li>
          </ul>
          <div className="navbar-dropdown-menu">
            <ul className="navbar-menu-container">
              {userData?.roleId.role === ROLES.SUPER_ADMIN && (
                <li className="d-flex gap-2 align-items-center me-3">
                  <button
                    className="btn btn-dark nav-link text-light px-3"
                    id="syncData"
                    onClick={() => {
                      setIsDisabled(true);
                      dispatch(
                        syncData({
                          cb: () => {
                            setIsDisabled(false);
                            navigate("/");
                          },
                        })
                      );
                    }}
                    disabled={isDisabled}
                  >
                    Sync Data
                  </button>
                  <button
                    className="btn btn-success nav-link text-light px-3"
                    id="downloadExcel"
                    onClick={() => {
                      setIsDisabled(true);
                      dispatch(
                        downloadExcel({
                          cb: (res, metaInfo) => {
                            downloadFile(res, metaInfo);
                            setIsDisabled(false);
                          },
                        })
                      );
                    }}
                    disabled={isDisabled}
                  >
                    {excelLoading ? "Downloading..." : "Export Excel"}
                  </button>
                </li>
              )}
              <li className="d-flex align-items-center">
                <button
                  onClick={handleShow}
                  className="btn btn-danger text-white"
                >
                  Logout
                </button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header className="bg-light" closeButton>
                    <Modal.Title>Are you sure you want to Logout</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="bg-light">
                    <Button className="no-logout" onClick={handleClose}>
                      No
                    </Button>
                    <Button className="yes-logout" onClick={loggedOut}>
                      {logoutLoading ? (
                        <div className="loading-login justify-content-center align-items-center w-100 ">
                          <div className="spinner-border"></div>
                        </div>
                      ) : (
                        "Yes"
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
