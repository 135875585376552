import React, { useEffect, useState } from "react";
import "./LoginForm.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { MdOutlineLock, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUserData, navigateUser } from "../../utils/helper.utils";
import { login } from "../../redux/user/httpCalls.user";
import CustomInput from "../../components/customInput/CustomInput";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter"),
});

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const TOKEN = getToken();
  const userData = getUserData();
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (TOKEN) {
      navigateUser(userData.roleId.role, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values) => {
    const formdata = {
      email: values.email,
      password: values.password,
    };
    dispatch(
      login({
        ...formdata,
        cb: (role) => {
          navigateUser(role, navigate);
        },
      })
    );
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center spinner-container text-white h-100">
          <div
            className="spinner-border"
            role="status"
            style={{ height: "5rem", width: "5rem" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : !TOKEN ? (
        <div className="login_form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form className="login">
                <div className="avatar">
                  <MdOutlineLock />
                  {/* <img src={logo} alt="logo" width={"100%"}/> */}
                </div>
                <h2 className="mb-3 text-center">Login</h2>
                <CustomInput
                  fieldType="text"
                  fieldName="email"
                  label="Email *"
                  fieldValue={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <div className="position-relative w-100">
                  <CustomInput
                    fieldType={showPassword ? "text" : "password"}
                    fieldName="password"
                    label="Password *"
                    fieldValue={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <div
                    className="position-absolute eye-icon"
                    onClick={() => setShowPassword((value) => !value)}
                  >
                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </div>
                </div>
                <button
                  type="submit"
                  variant="contained"
                  className="loginbtn"
                  disabled={loading}
                >
                  Login
                  {/* {loading ? (
                    <div className="loading-login justify-content-center align-items-center w-100">
                      <div className="spinner-border"></div>
                    </div>
                  ) : (
                    "Login"
                  )} */}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
    </>
  );
}

export default LoginForm;
