import { createSlice } from "@reduxjs/toolkit";
import { fetchSearchStudents, fetchStudents } from "./httpCalls.students";
import { ERROR_TOAST, STATUS } from "../../utils/Constants";
import { toast } from "react-toastify";

const initialState = {
  students: [],
  //   studentReport: {},
  metadata: {},
  loading: false,
};

const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    resetSurvey: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchStudents.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { data, success, metadata } = action.payload;
            if (success) {
              state.students = data;
              state.metadata = metadata;
            } else {
              toast.error(data?.message, { toastId: ERROR_TOAST.GET_STUDENTS });
              state.status = STATUS.ERROR;
            }
          }
        } catch (error) {
          console.log(error, "CATCH ERROR --------surveySlice.jsx---------");
        }
        state.loading = false;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchSearchStudents.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchSearchStudents.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { data, success, metadata } = action.payload;
            if (success) {
              state.students = data;
              state.metadata = metadata;
            } else {
              state.students = [];
              toast.error(data?.message, {
                toastId: ERROR_TOAST.SEARCH_RESULT,
              });
            }
          }
        } catch (error) {
          console.log(error, "CATCH ERROR --------surveySlice.jsx---------");
        }
        state.loading = false;
      })
      .addCase(fetchSearchStudents.rejected, (state, action) => {
        state.loading = false;
      });
    //   .addCase(fetchStudentReport.pending, (state, action) => {
    //     state.status = STATUS.LOADING;
    //   })
    //   .addCase(fetchStudentReport.fulfilled, (state, action) => {
    //     try {
    //       if (action.payload) {
    //         const { data, success } = action.payload;
    //         if (success) {
    //           state.status = STATUS.IDLE;
    //           state.studentReport = data;
    //         } else {
    //           toast.error(data?.message, {
    //             toastId: ERROR_TOAST.GET_STUDENT_REPORT,
    //           });
    //           state.status = STATUS.ERROR;
    //         }
    //       }
    //     } catch (error) {
    //       console.log(error, "CATCH ERROR --------surveySlice.jsx---------");
    //     }
    //   })
    //   .addCase(fetchStudentReport.rejected, (state, action) => {
    //     state.status = STATUS.ERROR;
    //   })
  },
});

export const { resetSurvey } = studentSlice.actions;
export default studentSlice.reducer;
