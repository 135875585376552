import React, { useRef } from "react";
import styles from "./customInput.module.css";

const CustomInput = ({
  label = "",
  fieldType = "text",
  fieldName = "",
  fieldValue = "",
  handleChange = () => {},
  handleBlur = () => {},
  errors = "",
  touched = "",
  className = null,
  container_styles = "",
  disabled = false,
}) => {
  const inputRef = useRef(null);

  return (
    <div
      className={`d-flex flex-column position-relative ${styles.marginbottom_22} ${styles.input_container} ${container_styles}`}
    >
      <input
        ref={inputRef}
        id={fieldName}
        type={fieldType}
        name={fieldName}
        onChange={handleChange}
        onBlur={handleBlur}
        value={fieldValue[fieldName]}
        placeholder=" "
        className={`${styles.custom_input}  ${className} ${
          errors[fieldName] && touched[fieldName]
            ? styles.custom_input_error
            : ""
        }`}
        disabled={disabled}
      />
      <div
        className={`${styles.custom_label} position-absolute ${
          errors[fieldName] && touched[fieldName]
            ? styles.custom_label_error
            : ""
        }`}
        onClick={() => {
          inputRef.current.focus();
        }}
      >
        <span>{label}</span>
      </div>
      {errors[fieldName] && touched[fieldName] ? (
        <div
          className={`${styles.error_message} position-absolute d-flex align-items-center`}
        >
          <span>{errors[fieldName]}</span>
        </div>
      ) : null}
    </div>
  );
};

export default CustomInput;
