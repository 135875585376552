import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUniversities } from "../../redux/university/httpCalls.university";
import { useEffect, useState } from "react";
import { Pagination } from "../../components";
import ComponentHeader from "../../components/component-header";
import CardViewWithoutDownloadBtn from "../../components/cardview/CardViewWithoutDownloadBtn";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";

const University = () => {
  const dispatch = useDispatch();
  const { universities, metadata, loading } = useSelector(
    (state) => state.university
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [universityData, setUniversityData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    //TODO: Add Check role and do action common function to pass callback function and role to access
    dispatch(fetchUniversities());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUniversityData(universities);
  }, [universities]);

  return (
    <>
      <div className="mt-3">
        <ComponentHeader
          heading="University List"
          searchPlaceHolder="Search University"
          setCurrentPage={setCurrentPage}
          showBackBtn={false}
          showSearchBox={false}
        />
      </div>
      <div className="d-flex flex-wrap gap-3">
        {loading ? (
          <SkeletonLoader />
        ) : universityData?.length ? (
          universityData.map((university, index) => {
            return (
              <CardViewWithoutDownloadBtn
                key={index}
                cardClickEvent={() =>
                  navigate(`/university/${university?.universityName}`)
                }
                cardContent={university?.universityName}
              />
            );
          })
        ) : (
          <p className="text-light"> No Univeristy Found.</p>
        )}
      </div>
      <Pagination
        metadata={metadata}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default University;
