import surveyLogo from "../../assets/images/logo.png";
import styles from "./cardview.module.css";
import DownloadBtn from "../download/DownloadBtn";
import ShareBtn from "../share/ShareBtn";
import { useState } from "react";

const CardWithDownloadBtn = ({
  cardContent = "Reload...",
  universityId = "",
  surveyId = "",
  showDeleteBtn = true,
  showShareBtn = true,
  cardClickEvent = () => {},
  deleteClickEvent = () => {},
  setValue = () => {},
  setOpenModal = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [isClickOnBtn, setIsClickOnBtn] = useState(true);

  const cb = (success, metadata) => {
    if (success) {
      window.open(
        process.env.REACT_APP_BACKEND_URL + "/view/" + metadata.publiclink,
        "_blank"
      );
    }
    setIsClickOnBtn(true);
    setLoading(false);
  };

  return (
    <div
      className={`d-flex ${styles.download_card_container}`}
      onClick={() => {
        if (isClickOnBtn) {
          setIsClickOnBtn(false);
          setLoading(true);
          cardClickEvent(universityId, cardContent, surveyId, cb);
        }
      }}
    >
      <div className={styles.card_logo_container}>
        <img src={surveyLogo} alt="survey_logo" />
      </div>
      <div className="text-truncate">
        <strong>{cardContent}</strong>
      </div>
      {loading && <div className={`${styles.load} ${styles.loadInCard}`} />}
      <div className={styles.btn_group}>
        {showShareBtn && (
          <ShareBtn
            universityId={universityId}
            surveyId={surveyId}
            studentId={cardContent}
            setValue={setValue}
            setOpenModal={setOpenModal}
            setIsClickOnBtn={setIsClickOnBtn}
            isClickOnBtn={isClickOnBtn}
            btnClickEvent={cardClickEvent}
          />
        )}
        {showDeleteBtn && (
          <DownloadBtn
            universityId={universityId}
            surveyId={surveyId}
            studentId={cardContent}
            setIsClickOnBtn={setIsClickOnBtn}
            isClickOnBtn={isClickOnBtn}
            btnClickEvent={cardClickEvent}
          />
        )}
      </div>
    </div>
  );
};

export default CardWithDownloadBtn;
