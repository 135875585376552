import React from "react";
import "./SearchBox.css";

const SearchBox = ({
  setCurrentPage,
  setSearchKey,
  searchPlaceHolder = "Search...",
}) => {
  return (
    <>
      <div className="d-flex">
        <input
          type="text"
          className="form-control"
          placeholder={searchPlaceHolder}
          onChange={(e) => {
            setCurrentPage(0);
            setSearchKey(e.target.value);
          }}
        />
      </div>
    </>
  );
};

export default SearchBox;
