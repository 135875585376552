const SyncBanner = () => {
  return (
    <div>
      <>
        <div className="bg-warning  d-flex position-fixed top-0 start-0 end-0 h-100 w-100 justify-content-center align-items-center z-100">
          <div className="text-white d-flex align-items-center justify-content-center gap-3 ">
            <h1>
              <strong>Sync Data</strong> Please Wait
            </h1>

            <div className="loading-login   text-dark mb-2  ">
              <div className="spinner-border" />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SyncBanner;
