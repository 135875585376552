import React from "react";
import "./NoMatch.css";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import nomatch from "../../assets/images/error404.png";

function NoMatch() {
  const navigate = useNavigate();

  return (
    <div className="no_match_page">
      <div className="container box">
        <div className="text-center">
          <button
            variant="outlined"
            className="d-flex btn btn-outline-primary justify-content-center align-items-center gap-1"
            onClick={() => navigate(-1)}
          >
            <HiArrowLeft /> Back
          </button>
          <img
            src={nomatch}
            alt="404 error"
            className="mx-auto mb-2 image_404"
          />
          <span>
            Sorry, The page you're looking for does not exist, check the URL and
            try again.
          </span>
        </div>
        <br />
      </div>
    </div>
  );
}

export default NoMatch;
