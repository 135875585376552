import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Styles from "../../components/Loader/SkeletonLoader.module.css";
import { Pagination } from "../../components";
import ComponentHeader from "../../components/component-header";
import CardWithDownloadBtn from "../../components/cardview/CardWithDownloadBtn";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal } from "react-bootstrap";
import { IoIosCopy } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  fetchSearchStudents,
  fetchStudents,
} from "../../redux/students/httpCalls.students";
import { downloadStudentReport } from "../../redux/downloadPdf/httpCalls.downloadPdf";
import "../../assets/css/main.css";

const StudentList = () => {
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [recordLimit, setRecordLimit] = useState(20);
  const [studentData, setStudentData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [value, setValue] = useState("");
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {isCopied ? "Copied" : "Copy url"}
    </Tooltip>
  );

  const { students, loading, metadata } = useSelector(
    (state) => state.students
  );
  const { loading: pdfLoading, metadata: pdfMetaData } = useSelector(
    (state) => state.downloadPdf
  );
  const { universityId, surveyId } = useParams();

  const dispatch = useDispatch();

  const dispatchDownloadPdf = (universityId, cardContent, surveyId, cb) => {
    dispatch(
      downloadStudentReport({
        universityName: universityId,
        identificationKey: cardContent,
        surveyId,
        cb,
      })
    );
  };

  useEffect(() => {
    setValue(
      process.env.REACT_APP_BACKEND_URL + "/view/" + pdfMetaData.publiclink
    );
  }, [pdfLoading, pdfMetaData]);

  useEffect(() => {
    setStudentData(students);
  }, [students]);

  useEffect(() => {
    if (searchKey.length) {
      dispatch(
        fetchSearchStudents({
          surveyId,
          universityName: universityId,
          limit: 10,
          page: 0,
          identificationKey: searchKey,
        })
      );
    } else {
      if (universityId && surveyId)
        dispatch(
          fetchStudents({
            universityId,
            surveyId,
            pagination: {
              limit: recordLimit,
              page: currentPage,
            },
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchKey, recordLimit]);

  return (
    <div className="mt-3">
      <Modal
        show={openModal}
        onHide={() => {
          setIsCopied((copy) => !copy);
          setOpenModal((modal) => !modal);
        }}
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title>Share PDF</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <label htmlFor="link">
            {pdfLoading
              ? "wait for few seconds"
              : "You can share the PDF using the following URL:"}
          </label>

          {pdfLoading ? (
            <div>
              <div className={`${Styles.card} position-relative w-100 `}>
                {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                <h1
                  className={`${Styles.post_title} w-100  position-absolute skeleton-loader`}
                ></h1>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                type="text"
                name="link"
                id="link"
                className="form-control modal-input-field"
                disabled
                value={value}
              />
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span
                  style={{ position: "absolute", top: "3px", right: "5px" }}
                >
                  <IoIosCopy
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(value);
                      setIsCopied(true);
                    }}
                  />
                </span>
              </OverlayTrigger>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* {isPdfModal ? (
        <PdfModal
          universityId={universityId}
          surveyId={surveyId}
          studentId={studentId}
          setIsPdfModal={setIsPdfModal}
          isPdfModal={isPdfModal}
        />
      ) : null} */}

      <ComponentHeader
        heading="Students"
        searchPlaceHolder="Search with student id"
        setCurrentPage={setCurrentPage}
        setSearchKey={setSearchKey}
      />
      {loading ? (
        <SkeletonLoader recordLimit={recordLimit} />
      ) : studentData?.length ? (
        <div className="d-flex flex-wrap gap-3">
          {studentData.map((student, index) => (
            <CardWithDownloadBtn
              cardClickEvent={dispatchDownloadPdf}
              key={index}
              cardContent={student["_id"]}
              universityId={universityId}
              surveyId={surveyId}
              setValue={setValue}
              setOpenModal={setOpenModal}
            />
          ))}
        </div>
      ) : (
        <p className="text-light">No Student Found.</p>
      )}
      <Pagination
        metadata={metadata}
        setCurrentPage={setCurrentPage}
        setRecordLimit={setRecordLimit}
        currentPage={currentPage}
      />
    </div>
  );
};
export default StudentList;
