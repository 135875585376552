import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TOAST, STATUS } from "../../utils/Constants";
import { fetchSurveys } from "./httpCalls.survey";
import { toast } from "react-toastify";

const initialState = {
  surveys: [],
  metadata: {},
  loading: false,
};

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    resetSurvey: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveys.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchSurveys.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { data, success, metadata } = action.payload;
            if (success) {
              state.status = STATUS.IDLE;
              state.surveys = data?.length ? data[0].surveys : [];
              state.metadata = metadata;
            } else {
              toast.error(data?.message, { toastId: ERROR_TOAST.GET_SURVEYS });
              state.status = STATUS.ERROR;
            }
          }
        } catch (error) {
          console.log(error, "CATCH ERROR --------surveySlice.jsx---------");
        }
        state.loading = false;
      })
      .addCase(fetchSurveys.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { resetSurvey } = surveySlice.actions;
export default surveySlice.reducer;
