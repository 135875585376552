import React, { useEffect, useState } from "react";
import BookLoader from "../components/Loader/BookLoader";
import { getToken, getUserData } from "../utils/helper.utils";
import { ROLES } from "../utils/Constants";
import { Navigate } from "react-router-dom";

const LoadingTimmer = 1000;

const InitialAppLoader = () => {
  const JWT_TOKEN = getToken();
  const userData = getUserData();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let x = setTimeout(() => {
      setIsLoading(false);
    }, LoadingTimmer);

    return () => clearTimeout(x);
  }, []);

  return isLoading ? (
    <BookLoader />
  ) : (
    <CheckAuthentication JWT_TOKEN={JWT_TOKEN} userData={userData} />
  );
};

export const CheckAuthentication = ({ JWT_TOKEN, userData }) => {
  if (JWT_TOKEN) {
    if (userData?.roleId?.role === ROLES.UNIVERSITY_ADMIN) {
      return <Navigate to={"/surveys"} />;
    } else if (userData?.roleId?.role === ROLES.SUPER_ADMIN) {
      return <Navigate to={"/university"} />;
    } else {
      return <Navigate to={"/login"} />;
    }
  } else {
    return <Navigate to={"/login"} />;
  }
};

export default InitialAppLoader;
