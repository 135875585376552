import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../config/http";

export const fetchSurveys = createAsyncThunk(
  "survey/fetchSurveys",
  async (universityId) => {
    const res = await http.get(`/survey/list/${universityId}`);
    return res;
  }
);
