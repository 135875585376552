import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../config/http";

export const downloadStudentReport = createAsyncThunk(
  "survey/downloadStudentReport",
  async (pathParams) => {
    const { universityName, identificationKey, surveyId, cb } = pathParams;
    const res = await http.get(
      `/survey/pdf/${universityName}/${identificationKey}/${surveyId}`,
      {
        timeout: null,
        headers: {
          "content-type": "application/pdf",
        },
      }
    );
    return { ...res, cb };
  }
);
