import React from "react";
import styles from "./SkeletonLoader.module.css";

const SkeletonLoader = ({ recordLimit = 2 }) => {
  const { card, profile_thumb, post_title, container } = styles;
  const cardNum = parseInt(recordLimit);
  return (
    <>
      <div className={container}>
        {Array(cardNum)
          .fill(1)
          .map((item, index) => (
            <div className={card} key={index}>
              <div className={profile_thumb}></div>
              {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
              <h1 className={post_title}></h1>
            </div>
          ))}
      </div>
    </>
  );
};

export default SkeletonLoader;
