import { Navigate, Outlet } from "react-router-dom";
import { Navbar } from "../components";
import { getToken } from "../utils/helper.utils";

function ProtectedRoute() {
  const TOKEN = getToken();

  if (!TOKEN) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <>
        <Navbar />
        <div className="container">
          <Outlet />
        </div>
      </>
    );
  }
}

export default ProtectedRoute;
