import { createSlice } from "@reduxjs/toolkit";
import { downloadStudentReport } from "./httpCalls.downloadPdf";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  metadata: {},
  data: {},
};
const downloadPdfSlice = createSlice({
  name: "downloadPdf",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(downloadStudentReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadStudentReport.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { success, message, metadata, data, cb } = action.payload;
            if (success) {
              state.metadata = metadata;
              state.data = data;
              toast.success(message, {
                toastId: "successdownloadStudentReport",
              });
            } else {
              toast.error(message, { toastId: "errordownloadStudentReport" });
            }
            state.loading = false;
            cb(success, metadata, data);
          }
        } catch (error) {
          console.error("surveSlice Error : ", error);
        }
      })
      .addCase(downloadStudentReport.rejected, (state, action) => {
        action.payload.cb(action.payload.success);
        state.loading = false;
      });
  },
});

export default downloadPdfSlice.reducer;
