import React from "react";
import { Route, Routes } from "react-router-dom";
import { NoMatch } from "./components/index";
import { LoginForm } from "./pages";
import ProtectedRoute from "./helpers/ProtectedRoute.js";
import { ToastContainer } from "react-toastify";
import Authorization from "./helpers/Authorization";
import Unauthorized from "./pages/Unauthorized";
import University from "./pages/university";
import SurveyList from "./pages/surveys/index";
import StudentList from "./pages/students";
import { ROLES } from "./utils/Constants";
import InitialAppLoader from "./helpers/InitialAppLoader";
import "./App.css";
import "./assets/css/main.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<InitialAppLoader />} />
        {/* Public Routes */}
        <Route path="/login" element={<LoginForm />} />
        {/* <Route path="register" element={<RegisterForm />} /> */}

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          {/* Admin Login :  Authorization is used to check if user is admin or not according to that will show route or navigate to unauthorized */}
          <Route
            path="surveys"
            element={<Authorization allowedRoles={[ROLES.UNIVERSITY_ADMIN]} />}
          >
            <Route index element={<SurveyList />} />
            <Route path=":universityId/:surveyId" element={<StudentList />} />
          </Route>
          {/* University Login :  Authorization is used to check if user is admin or not according to that will show route or navigate to unauthorized */}
          <Route
            path="university"
            element={<Authorization allowedRoles={[ROLES.SUPER_ADMIN]} />}
          >
            <Route index element={<University />} />
            <Route path=":universityId" element={<SurveyList />} />
            <Route path=":universityId/:surveyId" element={<StudentList />} />
          </Route>
        </Route>

        {/* Unauthorized route */}
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* No match found route */}
        <Route path="*" element={<NoMatch />} />
      </Routes>

      {/* Toast config */}
      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default App;
