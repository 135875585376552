import React from "react";
import styles from "../cardview/cardview.module.css";

import { BsFillShareFill } from "react-icons/bs";

const ShareBtn = ({
  universityId = "",
  surveyId = "",
  studentId = "",
  setOpenModal = () => {},
  setValue = () => {},
  setIsClickOnBtn = () => {},
  isClickOnBtn = true,
  btnClickEvent = () => {},
}) => {
  const cb = () => {
    setIsClickOnBtn(true);
  };

  return (
    <>
      <div
        className={`${styles.share_btn} ${styles.card_btn}`}
        onClick={(e) => {
          e.stopPropagation();
          setIsClickOnBtn(false);
          if (isClickOnBtn) {
            setOpenModal(true);
            btnClickEvent(universityId, studentId, surveyId, cb);
          }
        }}
      >
        <BsFillShareFill />
      </div>
    </>
  );
};

export default ShareBtn;
