import React, { useState } from "react";
import styles from "../cardview/cardview.module.css";
import { BsDownload } from "react-icons/bs";

import { downloadFile } from "../../utils/helper.utils";

const DownloadBtn = ({
  universityId = "",
  surveyId = "",
  studentId = "",
  setIsClickOnBtn = () => {},
  isClickOnBtn = true,
  btnClickEvent = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const cb = (success, metadata, data) => {
    if (success) {
      downloadFile(data, metadata);
    }
    setLoading(false);
    setIsClickOnBtn(true);
  };

  return (
    <>
      <div
        className={`${styles.download_btn} ${styles.card_btn}`}
        onClick={(e) => {
          e.stopPropagation();
          if (isClickOnBtn) {
            btnClickEvent(universityId, studentId, surveyId, cb);
            setLoading(true);
            setIsClickOnBtn(false);
          }
        }}
      >
        {loading ? <div className={styles.load} /> : <BsDownload />}
      </div>
    </>
  );
};

export default DownloadBtn;
