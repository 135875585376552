import surveyLogo from "../../assets/images/logo.png";
import styles from "./cardview.module.css";

const CardViewWithoutDownloadBtn = ({
  cardContent = "(Unknown)",
  cardClickEvent = () => {},
}) => {
  return (
    <div className={`d-flex ${styles.card_container}`} onClick={cardClickEvent}>
      <div className={styles.card_logo_container}>
        <img src={surveyLogo} alt="survey_logo" />
      </div>
      <div className="text-truncate">
        <strong>{cardContent}</strong>
      </div>
    </div>
  );
};

export default CardViewWithoutDownloadBtn;
