import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import surveyReducer from "./survey/surveySlice";
import universityReducer from "./university/universitySlice";
import studentReducer from "./students/studentsSlice";
import downloadPdfReducer from "./downloadPdf/downloadPdf.slice";
import exportExcelReducer from "./exportExcel/exportExcelSlice";
import SyncDataReducer from "./syncData/sync.slice";

const store = configureStore({
  reducer: {
    user: userReducer,
    survey: surveyReducer,
    university: universityReducer,
    students: studentReducer,
    downloadPdf: downloadPdfReducer,
    exportExcel: exportExcelReducer,
    syncData: SyncDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
