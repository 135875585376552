import { createSlice } from "@reduxjs/toolkit";
import { downloadExcel } from "./httpCalls.exportExcel";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
};

const exportSlice = createSlice({
  name: "exportExcel",
  initialState,
  reducers: {
    resetSurvey: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(downloadExcel.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadExcel.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { data, success, cb, message, metadata } = action.payload;
            if (success) {
              toast.success(message, { toastId: "successdownloadExcel" });
              cb(data, metadata);
            } else {
              toast.error(message, { toastId: "errordownloadExcel" });
            }
          }
        } catch (error) {
          console.error("surveSlice Error : ", error);
        }
        state.loading = false;
      })
      .addCase(downloadExcel.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default exportSlice.reducer;
