import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../config/http";

export const downloadExcel = createAsyncThunk(
  "user/downloadExcel",
  async (params) => {
    const { cb } = params;
    const res = await http.get(`/survey/excel-sheets`, { timeout: null });
    return { ...res, cb };
  }
);
