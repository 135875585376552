import { toast } from "react-toastify";
import { syncData } from "./httpCalls.sync";
import { createSlice } from "@reduxjs/toolkit";
import { ERROR_TOAST, SUCCESS_TOAST } from "../../utils/Constants";

const initialState = {
  loading: false,
};

const syncSlice = createSlice({
  name: "sync",
  initialState,
  reducers: {
    resetSurvey: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(syncData.pending, (state) => {
        state.loading = true;
        toast.info("Please wait while we fetching data");
      })
      .addCase(syncData.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { success, cb, message } = action.payload;
            if (success) {
              toast.success(message, { toastId: SUCCESS_TOAST.SYNC });
              cb();
            } else {
              toast.error(message, { toastId: ERROR_TOAST.SYNC });
            }
          }
        } catch (error) {
          console.log(error, "CATCH ERROR --------surveySlice.jsx---------");
        }
        state.loading = false;
      })
      .addCase(syncData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default syncSlice.reducer;
