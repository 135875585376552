import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ERROR_TOAST, STATUS, SUCCESS_TOAST } from "../../utils/Constants";
import { setToken, setUserData } from "../../utils/helper.utils";
import { getUser, login, logout } from "./httpCalls.user";

const initialState = {
  userInfo: {},
  role: "",
  toggle: false,
  loading: false,
  successMessage: "",
  errorMessage: "",
  status: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = STATUS.LOADING;
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { data, message, success, cb } = action.payload;
            if (success) {
              setUserData(data);
              setToken(data.accessToken);
              state.role = data?.roleId?.role;
              state.userInfo = data;
              state.successMessage = message;
              state.toggle = true;
              toast.success(message, { toastId: SUCCESS_TOAST.LOGIN });
              state.status = STATUS.IDLE;
              state.loading = false;
              cb(data?.roleId?.role);
            } else {
              toast.error(data?.message, { toastId: ERROR_TOAST.LOGIN });
              state.status = STATUS.ERROR;
              state.errorMessage = data?.message;
            }
          }
        } catch (error) {
          console.error("Error in userSlice : ", error);
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.status = STATUS.ERROR;
        state.loading = false;
      })
      //logout start

      .addCase(logout.pending, (state, action) => {
        state.status = STATUS.LOADING;
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { success, message, cb } = action.payload;
            if (success) {
              localStorage.clear();
              cb();
              state.status = STATUS.IDLE;
              state.loading = false;
              toast.success(message, { toastId: SUCCESS_TOAST.LOGOUT });
            }
          }
        } catch (error) {
          console.log(error, "CATCH ERROR --------userSlice.jsx---------");
        }
      })
      .addCase(logout.rejected, (state, action) => {
        const { data } = action.payload;
        state.status = STATUS.ERROR;
        state.loading = false;
        toast.error(data?.message, { toastId: ERROR_TOAST.LOGOUT });
      })

      //logout end
      .addCase(getUser.pending, (state, action) => {
        state.status = STATUS.LOADING;
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { data, success, message } = action.payload;
            if (success) {
              state.successMessage = message;
              state.status = STATUS.IDLE;
              state.loading = false;
              state.userInfo = data[0];
            } else {
              toast.error(data?.message, { toastId: ERROR_TOAST.GET_USERS });
              state.status = STATUS.ERROR;
              state.errorMessage = message;
            }
          }
        } catch (error) {
          console.log(error, "CATCH ERROR --------userSlice.jsx---------");
        }
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = STATUS.ERROR;
        state.loading = false;
      });
  },
});

export const { resetUser } = userSlice.actions;
export default userSlice.reducer;
