import ReactPaginate from "react-paginate";
import "./Pagination.css";

const Pagination = ({
  metadata,
  currentPage = 0,
  setCurrentPage,
  recordLimit,
  setRecordLimit,
}) => {
  const handleResultPerPage = (e) => {
    const value = e.target.value;
    setRecordLimit(value);
  };
  return (
    <>
      <div className="mt-4">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          forcePage={currentPage}
          pageCount={metadata?.totalPage > 1 ? metadata?.totalPage : 0}
          renderOnZeroPageCount={null}
          previousLabel="<"
          className="custom-pagination"
          activeClassName="active-page"
          previousClassName="previous-pagination"
          nextClassName="next-pagination"
          disabledClassName="disable-pagination"
        />
        {metadata.totalRecord && (
          <select
            value={recordLimit}
            onChange={handleResultPerPage}
            className="drop"
          >
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        )}
      </div>
    </>
  );
};

export default Pagination;
