import { useNavigate } from "react-router-dom";
import SearchBox from "../searchbox/SearchBox";
import { HiArrowLeft } from "react-icons/hi";

const ComponentHeader = ({
  showBackBtn = true,
  showSearchBox = true,
  heading,
  setSearchKey,
  setCurrentPage,
  searchPlaceHolder = "Search...",
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="row pb-4">
        <div className="col-4 ">
          {showBackBtn && (
            <button
              className="btn btn-light d-flex justify-content-center align-items-center"
              onClick={() => navigate(-1)}
            >
              <HiArrowLeft className="pe-1 fs-4 text-bold" />
              Back
            </button>
          )}
        </div>
        <div
          className="bold col-4 text-light text-center"
          style={{ fontSize: "25px" }}
        >
          {heading}
        </div>

        <div className="col-4">
          {showSearchBox && (
            <SearchBox
              setSearchKey={setSearchKey}
              setCurrentPage={setCurrentPage}
              searchPlaceHolder={searchPlaceHolder}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ComponentHeader;
