import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../utils/Constants";
import { toast } from "react-toastify";
import { fetchUniversities } from "./httpCalls.university";

const initialState = {
  universities: [],
  metadata: {},
  loading: false,
};

const universitySlice = createSlice({
  name: "university",
  initialState,
  reducers: {
    resetUniversity: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUniversities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUniversities.fulfilled, (state, action) => {
        try {
          if (action.payload) {
            const { data, success } = action.payload;
            if (success) {
              state.status = STATUS.IDLE;
              state.universities = data;
            } else {
              toast.error(data?.message, { toastId: "errorFetchUniveristy" });
              state.status = STATUS.ERROR;
            }
          }
        } catch (error) {
          toast.error("Something Went wrong in fetching university", {
            toastId: "errorFetchUniveristy",
          });
        }
        state.loading = false;
      })
      .addCase(fetchUniversities.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default universitySlice.reducer;
