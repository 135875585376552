import axios from "axios";
import { getToken } from "../utils/helper.utils";

const http = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BACKEND_VERSION,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 7000,
});
http.interceptors.request.use(
  (config) => {
    const JWT_TOKEN = getToken();
    if (JWT_TOKEN) {
      config.headers.Authorization = `Bearer ${JWT_TOKEN}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
http.interceptors.response.use(
  (res) => {
    // do something with response
    const { data, status } = res;
    return { ...data, status };
  },
  (err) => {
    // do something with error
    if (err.message === "Network Error")
      return {
        success: false,
        message: "Network Error",
        metdata: {},
      };
    const { data, status } = err.response;
    if (status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return { data, status };
  }
);
export default http;
