import { Navigate, Outlet } from "react-router-dom";
import { getUserData } from "../utils/helper.utils";

const Authorization = ({ allowedRoles }) => {
  // Getting user information from local storage
  const userData = getUserData();
  return (
    <>
      {/* Checking wheather role is allowed or not if not then navigate to unauthorized */}
      {allowedRoles.includes(userData.roleId.role) ? (
        <Outlet />
      ) : (
        <Navigate to="/unauthorized" replace />
      )}
    </>
  );
};

export default Authorization;
