import CryptoJS from "crypto-js";
import { ROLES } from "./Constants";

export const getUserData = () => {
  let user = null;
  try {
    const encUser =
      localStorage.getItem("userInfo") &&
      CryptoJS.AES.decrypt(
        localStorage.getItem("userInfo"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
    user = encUser ? JSON.parse(encUser) : null;
  } catch (error) {
    localStorage.removeItem("userInfo");
    console.log(
      ">>>>: src/utils/helper.utils.js  : getUserData -> error",
      error
    );
    user = null;
  }
  return user;
};

export const setUserData = (user) => {
  const decrypt = CryptoJS.AES.encrypt(
    JSON.stringify(user),
    process.env.REACT_APP_SECRET_KEY
  );
  localStorage.setItem("userInfo", decrypt);
  try {
    if (user) {
      localStorage.setItem("userInfo", decrypt);
    } else {
      localStorage.removeItem("userInfo");
    }
  } catch (error) {
    console.log(
      ">>>>: src/utils/helper.utils.js : setUserData -> error",
      error
    );
  }
};

export const getToken = () => {
  let token = null;
  try {
    const encToken =
      localStorage.getItem("accessToken") &&
      CryptoJS.AES.decrypt(
        localStorage.getItem("accessToken"),
        process.env.REACT_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
    token = encToken ? JSON.parse(encToken) : null;
  } catch (error) {
    localStorage.removeItem("accessToken");
    console.log(">>>>: src/utils/helper.utils.js  : getToken -> error", error);
    token = null;
  }
  return token;
};

export const setToken = (token) => {
  const decrypt = CryptoJS.AES.encrypt(
    JSON.stringify(token),
    process.env.REACT_APP_SECRET_KEY
  );
  localStorage.setItem("accessToken", decrypt);
  try {
    if (token) {
      localStorage.setItem("accessToken", decrypt);
    } else {
      localStorage.removeItem("accessToken");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};

export const navigateUser = (role, navigate) => {
  if (role === ROLES.SUPER_ADMIN) {
    navigate("/university");
  } else if (role === ROLES.UNIVERSITY_ADMIN) {
    navigate("/surveys");
  } else {
    localStorage.clear();
    navigate("/");
  }
};

// Convert PDF to Base64 Image
export const pdfToImages = async (pdf, pageNumber = 1) => {
  const pdfPage = await pdf.getPage(pageNumber);
  const viewport = pdfPage.getViewport({ scale: 3.1 });
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  const renderContext = {
    canvasContext: context,
    viewport: viewport,
    disableFontFace: true,
    disableTextLayer: true,
    disableCanvasToImageConversion: false,
    pdfBug: false,
    nativeImageDecoderSupport: "none",
    // Image compression from 0 to 1. 1 means higher resolution
    imageCompressionQuality: 1,
  };
  await pdfPage.render(renderContext).promise;
  return canvas.toDataURL(); // Returns a base64-encoded image
};

// call this funtion to  download File
export const downloadFile = (file, metaInfo) => {
  const link = document.createElement("a");
  link.download = metaInfo?.fileName || "error";
  link.href = file;
  document.body.appendChild(link);
  link.click();
};

export const decodeBase64 = (base64String) => {
  const decodedString = window.atob(base64String);
  const arrayBuffer = new ArrayBuffer(decodedString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < decodedString.length; i++) {
    uint8Array[i] = decodedString.charCodeAt(i);
  }

  return uint8Array;
};
